import VueCookies from "vue-cookies";

var storage = {
  setItem(key, value, expired) {
    VueCookies.set(key, value, "7d", "/", null, true, "Lax");
  },
  getItem(key) {
    return VueCookies.get(key);
  },
  removeItem(key) {
    VueCookies.remove(key);
  },
};

export default storage;
