import Vue from "vue"
import Router from "vue-router"
import store from '../js/store'
import storage from "../js/storage";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import dayjs from "dayjs"
NProgress.configure({ showSpinner: false });
Vue.use(Router);
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/test",
      component: resolve => require(["../Page/test.vue"], resolve)
    },
    {
      path: "/login",
      component: resolve => require(["../Page/login.vue"], resolve)
    },
    {
      path: "/forget-password",
      name: "sendlink",
      component: resolve => require(["../Page/sendLink.vue"], resolve)
    },
    {
      path: "/forget-password/:id",
      name: "forget",
      component: resolve => require(["../Page/forgetpassword.vue"], resolve)
    },
    {
      path: "/person",
      name: "person",
      component: resolve => require(["../Page/personInfor.vue"], resolve)
    },
    {
      path: "/enroll",
      name: "enroll",
      component: resolve => require(["../Page/enroll.vue"], resolve)
    },
    // {
    //   path: "/qb-create-bill/:id",
    //   name: "qb-create-bill",
    //   component: resolve => require(["../Page/createBill.vue"], resolve)
    // },

    {
      path: "/notification-tenant-payment",
      name: "notification-tenant-payment",
      component: resolve => require(["../Page/NoticeTantent.vue"], resolve)
    },
    {
      path: "/notification-owner-release-sd",
      name: "notification-owner-release-sd",
      component: resolve => require(["../components/leases/OwnerRelease.vue"], resolve)
    },
    {
      path: '/',
      component: resolve => require(["../Page/main.vue"], resolve),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: resolve => require(["../components/dashboard/Dashboard.vue"], resolve),
        },
        {
          path: "/contacts",
          name: "contacts",
          component: resolve => require(["../components/tenants/Contacts.vue"], resolve),
        },
        {
          path: "/leases",
          name: "leases",
          component: resolve => require(["../components/leases/Leases.vue"], resolve),
        },
        {
          path: "/properties",
          name: "properties",
          component: resolve => require(["../components/properties/Properties.vue"], resolve),
        },
        {
          path: "/docs",
          name: "templates",
          component: resolve => require(["../components/templates/Templates.vue"], resolve)
        },
        {
          path: "/congdonand-coleman-agents",
          name: "agents",
          component: resolve => require(["../components/agent/Agents.vue"], resolve)
        },
        {
          path: "/tools-resources",
          name: "tools-resources",
          component: resolve => require(["../components/toolsResources/toolsResources.vue"], resolve)
        },
        {
          path: "/schedule",
          name: "schedule",
          component: resolve => require(["../components/schedule/Schedule.vue"], resolve)
        },
        // Contact 详情路由
        {
          path: "/contact",
          name: "create-contact",
          component: resolve => require(["../components/tenants/Contact.vue"], resolve)
        },
        {
          path: "/contact/:id/edit",
          name: "edit-contact",
          component: resolve => require(["../components/tenants/Contact/CreateContact.vue"], resolve)
        },
        {
          path: "/contact/:id",
          name: "show-contact",
          component: resolve => require(["../components/tenants/Contact.vue"], resolve)
        },
        // profile
        //  {
        //   path:"/profile",
        //   name:"profile",
        //   component:resolve=>require(["../components/profile"],resolve)
        // },
        // 博客
        {
          path: "/blogs",
          name: "blogs",
          component: resolve => require(["../components/blog/Blogs.vue"], resolve)
        },
        {
          path: "/blog",
          name: "creat_blog",
          component: resolve => require(["../components/blog/Blog.vue"], resolve)
        }, {
          path: "/blog/:id",
          name: "edit_blog",
          component: resolve => require(["../components/blog/Blog.vue"], resolve)
        },
        {
          path: "/lease-report",
          name: "leasereport",
          component: resolve => require(["../components/leaseReport/LeaseReport.vue"], resolve),
        },
        // 房屋详情路由
        {
          path: "/properties-base",
          component: resolve => require(["../components/properties/PropertyNavigation.vue"], resolve),
          children: [
            {
              path: "/property",
              name: "create-property",
              component: resolve => require(["../components/properties/Property.vue"], resolve)
            },
            {
              path: "/property/:id",
              name: "edit-property",
              component: resolve => require(["../components/properties/Property.vue"], resolve)
            },
            {
              path: "/property/:id/location",
              name: "property-location",
              component: resolve => require(["../components/properties/PropertyLocation.vue"], resolve)
            },
            {
              path: "/property/:id/descriptions",
              name: "property-descriptions",
              component: resolve => require(["../components/properties/PropertyDescriptions.vue"], resolve)
            },
            {
              path: "/property/:id/general",
              name: "property-general",
              component: resolve => require(["../components/properties/PropertyGeneral.vue"], resolve)
            },
            {
              path: "/property/:id/amenities",
              name: "property-amenities",
              component: resolve => require(["../components/properties/PropertyAmenities.vue"], resolve)
            },
            {
              path: "/property/:id/bedroomsandbathrooms",
              name: "property-bedroomsandbathrooms",
              component: resolve => require(["../components/properties/PropertyBedroomsAndBathrooms.vue"], resolve)
            },
            {
              path: "/property/:id/calendar",
              name: "property-calendar",
              component: resolve => require(["../components/properties/PropertyCalendar.vue"], resolve)
            },
            {
              path: "/property/:id/photo",
              name: "property-photo",
              component: resolve => require(["../components/properties/PropertyPhoto.vue"], resolve)
            },
            {
              path: "/property/:id/requirement",
              name: "property-requirement",
              component: resolve => require(["../components/properties/PropertyRequirement.vue"], resolve)
            },
            {
              path: "/property/:id/payment-information",
              name: "property-information",
              component: resolve => require(["../components/properties/PropertyPaymentInformation.vue"], resolve)
            },
            {
              path: "/property/:id/service",
              name: "property-service",
              component: resolve => require(["../components/properties/PropertyService.vue"], resolve)
            },
          ]
        },
        // 租约详情路由
        {
          path: "/lease-base",
          component: resolve => require(["../components/leases/Component/LeaseNavigation.vue"], resolve),
          children: [
            {
              path: "/lease",
              name: "create-lease",
              component: resolve => require(["../components/leases/LeaseDetails.vue"], resolve)
            },
            {
              path: "/lease/:id",
              name: "edit-lease",
              component: resolve => require(["../components/leases/LeaseDetails.vue"], resolve)
            },
            {
              path: "/lease/:id/payment",
              name: "lease-payment",
              component: resolve => require(["../components/leases/LeasePayments.vue"], resolve)
            },
            {
              path: "/lease/:id/agreement",
              name: "lease-agreement",
              component: resolve => require(["../components/leases/LeaseAgreement.vue"], resolve)
            },
            {
              path: "/lease/:id/security-deposit",
              name: "lease-security-deposit",
              component: resolve => require(["../components/leases/LeaseSecurityDeposit.vue"], resolve)
            },
          ]
        },
        // Resources
        {
          path: "/resources-base",
          component: resolve => require(["../components/resources/ResourceNav.vue"], resolve),
          children: [
            {
              path: "/resources/restaurants",
              name: "resources-restaurants",
              component: resolve => require(["../components/resources/Restaurants.vue"], resolve)
            },
            {
              path: "/resources/neigheorhoods",
              name: "resources-neigheorhoods",
              component: resolve => require(["../components/resources/Neigheorhoods.vue"], resolve)
            }, {
              path: "/resources/covid",
              name: "resources-covid",
              component: resolve => require(["../components/resources/Covid.vue"], resolve)
            },
            // {
            //   path: "/resources/covid/edit/:id",
            //   name: "resources-covid-edit",
            //   component: resolve => require(["../components/resources/Covid/CovidDetail.vue"], resolve)
            // },{
            //   path: "/resources/covid/edit",
            //   name: "resources-covid-create",
            //   component: resolve => require(["../components/resources/Covid/CovidDetail.vue"], resolve)
            // },
          ]
        },
        {
          path: "/revenue",
          name: "revenue",
          component: resolve => require(["../components/myRevenue/Revenue.vue"], resolve)
        },
        // sales
        {
          path: "/sales-listings",
          name: "sales-listing",
          component: resolve => require(["../components/salesListings/SalesListings.vue"], resolve)
        }, {
          path: "/sales-transaction",
          name: "sales-transaction",
          component: resolve => require(["../components/salesTransaction/SalesTransaction.vue"], resolve)
        },
        // cma
        {
          path: "/cma-base",
          component: resolve => require(["../components/cma/CMANav.vue"], resolve),
          children: [
            {
              path: "/subject-property",
              name: "subjectproperty",
              component: resolve => require(["../components/cma/SubjectProperty.vue"], resolve)
            },
            {
              path: "/property-detail/:id",
              name: "edit-property-detail",
              component: resolve => require(["../components/cma/PropertyDetail.vue"], resolve)
            },
            {
              path: "/property-detail",
              name: "create-property-detail",
              component: resolve => require(["../components/cma/PropertyDetail.vue"], resolve)
            },
            {
              path: "/property-detail/:id/comparable-sales",
              name: "comparable-sales",
              component: resolve => require(["../components/cma/ComparableSales.vue"], resolve)
            },
            {
              path: "/property-detail/:id/competitve-listings",
              name: "competitve-listings",
              component: resolve => require(["../components/cma/CompetitveListings.vue"], resolve)
            },
            {
              path: "/property-detail/:id/report-settings",
              name: "report-settings",
              component: resolve => require(["../components/cma/ReportSettings.vue"], resolve)
            },
            {
              path: "/property-detail/:id/review-share",
              name: "review-share",
              component: resolve => require(["../components/cma/ReviewShare.vue"], resolve)
            },
          ]
        },
      ]
    },
  ]
});
router.beforeEach((to, from, next) => {
  localStorage.setItem("count", dayjs())
  NProgress.start();
  if (to.path === "/test" && to.path === '/notification-tenant-payment' || to.path === "/testtable" || to.path === "/login" || to.path === "/register" || to.path === "/forget" || to.name === "qb-create-bill") { // 不需要登录也能访问的页面
    next();
    return;
  } else if (to.path === "/") {// 跳转到登录页面
    next("/dashboard");
    return;
  } else if (to.path === "/schedule" || to.path === "/blogs" || to.path === "/agents" || to.path === "/revenue") {
    if (storage.getItem("isType") === false) {
      next("/dashboard")
    } else {
      next()
    }
  } else if (!storage.getItem("token")) { // 没有登录跳转到登录页面
    sessionStorage.setItem("path", to.name)
    next("/login");
    store.commit('setGoBack', true); // 下次登录成功后，需要回退到上一个页面
    return;
  } else {
    next();
  }

});
router.afterEach(() => {
  NProgress.done();
});

export default router;
